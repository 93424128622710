.before-box::before {
    content: "";
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    position: absolute;
    bottom: 20px;
    height: 35%;
    background-color: theme("colors.yellow.600");
    transition: all var(--transition-smooth) ease;
}
.bg-pic-main {
    filter: brightness(25%);
}
