:root {
    --font-family: "Almarai";
    --side-navbar-collapsed-width: 3rem;
    /* --body-top: 0px; */
    --main-color: #1a564a;
}
@font-face {
    font-family: "FS Albert Arabic";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/fs-albert.ttf") format("truetype");
}
@font-face {
    font-family: "FS Albert Arabic";
    font-weight: bold;
    font-style: normal;
    src: url("./assets/fonts/fs-albert-700.ttf") format("truetype");
}
@font-face {
    font-family: "Somar";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Bold.otf") format("opentype");
}
@font-face {
    font-family: "AvenirArabic";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/AvenirArabic-Heavy.otf") format("opentype");
}
@font-face {
    font-family: "SansPlain";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-The-Sans-Plain-alinma.ttf") format("truetype");
}

.bg-main-color {
    background-color: var(--main-color);
}
.font-avn {
    font-family: "AvenirArabic", sans-serif;
}
.font-sans-plain {
    font-family: "SansPlain", sans-serif;
}
.font-som {
    font-family: "Somar", sans-serif;
}
/* body {
    height: 1000vh;
    direction: ltr;
} */
.pisitive-nav-top {
    top: calc(var(--navbar-height));
}

.font-lem {
    font-family: "Lemonada", sans-serif;
}
